import React, { Suspense } from 'react';
import { Coords, LocationOnMap } from '../../type.d';
import { Box as BaseBox } from 'components/Box/Box';
import LocationMarker from './LocationMarker';
import useMapContainer from './hooks/useMapContainer';
import { DEFAULT_CENTER_COORDS } from '../../constants';
import getRightLngLat from './getRightLngLat';
import LoadingIndicator from 'components/LoadingIndicator';
import useConvertPlusCodeToCoords from './hooks/useConvertPlusCodeToCoords';
interface Props {
  locations: LocationOnMap[];
  coords: Coords | null;
  selectedLocation: string;
}

const Map = ({ locations, coords, selectedLocation }: Props) => {
  const selectedLocationCoords = locations.find((location) => location.id === selectedLocation);
  const coordsFromSelectedLocation =
    useConvertPlusCodeToCoords(selectedLocationCoords?.plus_code ?? '') ?? selectedLocationCoords?.coords;
  const center =
    locations.length === 1 ? getRightLngLat(locations[0].coords) : getRightLngLat(coordsFromSelectedLocation);
  const { mapContainer, ref } = useMapContainer(center ?? DEFAULT_CENTER_COORDS);
  return (
    <BaseBox loading={false} style={{ height: '625px' }} ref={ref}>
      {mapContainer ? (
        <Suspense fallback={<LoadingIndicator />}>
          <LocationMarker locations={locations} map={mapContainer} />
        </Suspense>
      ) : null}
    </BaseBox>
  );
};

export default Map;
