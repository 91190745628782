import { Coords } from 'containers/LandingPage/DistributionLocationPage/type.d';
import { LocationOnMap } from '../../../type.d';
import ReactDOM from 'react-dom';
import React, { useEffect, useRef } from 'react';
import { onClickListener } from 'containers/LandingPage/DistributionLocationPage/reducer';

const useAdvanceMarker = (
  map: google.maps.Map,
  position: Coords,
  children: React.ReactNode,
  setOpenCardInfo?: (value: LocationOnMap | null) => void,
  data?: LocationOnMap,
  handleClick?: (id: string) => void,
) => {
  const markerRef = useRef<google.maps.marker.AdvancedMarkerElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!containerRef.current) {
      const container = document.createElement('div');
      containerRef.current = container;

      if (position.lat && position.lng) {
        markerRef.current = new google.maps.marker.AdvancedMarkerElement({
          map: map,
          position: position,
          content: container,
          gmpClickable: true,
        });
      }
    }

    return () => {
      if (containerRef.current && containerRef.current.parentNode) {
        containerRef.current.parentNode.removeChild(containerRef.current);
        containerRef.current = null;
      }
      if (markerRef.current) {
        markerRef.current.map = null;
      }
      markerRef.current = null;
    };
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      const root = <>{children}</>;
      ReactDOM.render(root, containerRef.current);
    }

    if (markerRef.current) {
      markerRef.current!.position = position;
      markerRef.current!.map = map;
    }

    const initialBounds = new google.maps.LatLngBounds();
    initialBounds.extend(position);

    map.setCenter(initialBounds.getCenter());

    let onClick: google.maps.MapsEventListener | undefined;

    if (handleClick) {
      if (data) {
        onClick = onClickListener(markerRef, handleClick, data?.id, setOpenCardInfo, data) as
          | google.maps.MapsEventListener
          | undefined;
      }
    }
    return () => {
      if (handleClick && onClick) {
        google.maps.event.removeListener(onClick);
      }
    };
  }, [map, position, children, data]);
};

export default useAdvanceMarker;
